.AppBackground {
  margin: 0;
  overflow: hidden;
  background-position: center center;
  /* background-attachment: fixed; */
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Myriad-Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: default;
  transition: 200ms;
  height: 100%;
  width: 100%;
  background-color: #fff;
}
@font-face {
  font-family: "Myriad-Pro-Bold";
  src: url("./assets/MYRIADPRO-BOLD.OTF");
}
@font-face {
  font-family: "Myriad-Pro";
  src: url("./assets/MYRIADPRO-REGULAR.OTF");
}
@font-face {
  font-family: "Myriad-Pro-Light";
  src: url("./assets/MyriadPro-Light.otf");
}
::placeholder {
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}
.pricesPageFormat {
  padding-top: 10em;
  padding-left: 4em;
  padding-right: 4em;
}
.pageTitle {
  text-align: center;
  color: #0070c9;
  font-size: 50pt;
  font-family: "Myriad-Pro-Bold";
}
.proWord {
  background: url("./assets/f9f493e99f9ee825b30acdd0bc37befb.jpg");
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pageDescription {
  text-align: center;
  font-size: 17pt;
}
.inputCustom {
  width: 20em !important;
  border-radius: 0.5em 0.5em 0 0 !important;
  padding: 0.5em !important;
}
.App {
  background-color: rgba(255, 255, 255, 0.7);
  transition: 200ms;
  height: 100%;
  width: 100%;
  padding-top: 1em;
  background-attachment: fixed;
  /* backdrop-filter: blur(3em) saturate(5); */
  cursor: default;
}
.blurBackground {
  background-color: rgba(255, 255, 255, 0) !important;
  /* backdrop-filter: blur(20px) saturate(180%); */
}
.errorPage {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 2em;
  border-radius: 1em;
  backdrop-filter: blur(20px) saturate(180%);
}
.errorPage h1 {
  color: #fff;
}
.menu {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.72);
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 3.7em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1em;
  padding-right: 1em;
  backdrop-filter: blur(20px) saturate(180%);
  z-index: 11;
  padding-left: 10em;
  padding-right: 10em;
  border-bottom-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-bottom-width: 0.1em;
}
.pages {
  /* background-color: #f00; */
  margin-left: 4em;
}
.pages span {
  padding: 0.4em;
  color: rgba(0, 0, 0, 0.7);
  margin-left: 3em;
  margin-right: 3em;
  font-size: 1em;
}
.pages span:hover {
  color: rgba(0, 0, 0, 1);
}
.titleMenu {
  color: #fff;
  font-size: 20pt;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: -0.03em;
  cursor: pointer;
}
.contentView {
  padding: 1em;
  color: #333;
  display: flex;
  flex-direction: row;
}
.leftSideView {
  width: 50vw;
  margin: 1em;
  padding: 1em;
  border-radius: 1em;
}
.topMessage {
  display: flex;
  margin: 1em;
  font-size: 17pt;
  font-weight: bold;
  justify-content: center;
}
.previewView {
  position: fixed;
  right: 0;
  top: 2%;
  bottom: 0;
  width: 50vw;
  margin: 1em;
  padding: 1em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fraseInput {
  width: 100%;
}
.inputText {
  border-radius: 0.5em;
  border: none;
  padding-top: 1em;
  padding-bottom: 1em;
  text-indent: 0.5em;
  width: 100%;
  font-size: 12pt;
  margin-top: 1em;
  color: rgba(0, 0, 0, 0.7);
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-color: #fff;
  transition: 200ms;
}
.range {
  padding: 0;
}
.inputColor {
  border-radius: 0.5em;
  border: none;
  /* padding-top: 0.5em;
  padding-bottom: 0.5em; */
  text-indent: 0.5em;
  width: 10em;
  margin-right: 1em;
  font-size: 12pt;
  margin-top: 1em;
  background-color: rgba(0, 0, 0, 0.2);
  transition: 200ms;
}
.inputText::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
label {
  color: rgba(255, 255, 255, 0.7);
}
.item {
  background-color: #000;
  /* position: relative; */
  width: 30em;
  height: 30em;
  border-radius: 1em;
  overflow: hidden;
  transition: 300ms;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* box-shadow: 0 1em 1em rgba(0, 0, 0, 0.25); */
}
.filter {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 300ms;
  transition-timing-function: ease-in-out;
  position: relative;
  z-index: 8;
}
.backgroundImageImg {
  width: 30em;
  height: 30em;
  position: absolute;
  z-index: 5;
  object-fit: cover !important;
}
.credits {
  display: flex;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0.5em;
  backdrop-filter: blur(1em);
  padding: 1em;
  position: fixed;
  bottom: 0;
  /* top: 0; */
  margin: 0.3em;
  z-index: 10;
  right: 0;
  text-decoration: none;
  color: #000;
}
.website {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}
span {
  transition: 200ms;
}
.downloadBtn {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1em;
  margin: 0.5em;
  border-radius: 0.5em;
  cursor: pointer;
  transition: 200ms;
}
.downloadBtn:hover {
  backdrop-filter: blur(1em);
}
.contentText {
  transition: 100ms;
  margin: 0.3em;
  font-size: 23pt;
  text-align: center;
  font-weight: bold;
}
.logo {
  width: 100%;
  transition: 100ms;
}
.logoImg {
  width: 2em;
  opacity: 0.7;
  cursor: pointer;
  transition: 300ms;
}
.logoImg:hover + .logoText {
  margin-left: 0.5em !important;
  opacity: 0.7 !important;
}
.logoText:hover {
  margin-left: 0.5em !important;
  opacity: 0.7 !important;
}
.logoText:hover + .logoImg {
  opacity: 0.7 !important;
}
.logoText {
  cursor: pointer;
  width: 8em;
  transition: 300ms;
  opacity: 0.7;
  padding-top: 0.5em;
  margin-left: 0.5em;
}
.logoRender {
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center center;
  right: 0;
  padding: 0.5em;
  padding: 1em;
}
.username {
  transition: 300ms;
  color: #fff;
  border-radius: 0.5em;
  background-color: rgba(255, 255, 255, 0.25);
  padding: 0.5em;
  margin: 0.5em;
  /* z-index: 7; */
}
.loadingView {
  padding-left: 1em;
  padding-right: 1em;
  color: #fff !important;
  transition: 200ms;
  position: fixed;
  z-index: 9;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(3em);
  background-color: rgba(255, 255, 255, 0.7);
}
.button {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  backdrop-filter: saturate(0);
  padding: 1em;
  border-radius: 1em;
  transition: 500ms;
}
.button:hover {
  backdrop-filter: saturate(50);
}
.warnView {
  backdrop-filter: blur(1em);
  padding-left: 1em;
  padding-right: 1em;
  color: #fff;
  transition: 200ms;
  position: fixed;
  z-index: 9;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.downloadRenderedImage {
  position: fixed;
  transition: 300ms;
  color: #000;
  right: 0;
  bottom: 0;
  background-color: rgb(31, 168, 3);
  padding: 1em;
  border-radius: 0.7em;
  margin: 1em;
  cursor: pointer;
  box-shadow: 0 0.5em 0.7em rgba(31, 168, 3, 0.5);
}
.renderImage {
  position: fixed;
  transition: 300ms;
  color: #fff;
  right: 0;
  bottom: 4em;
  background-color: rgb(31, 168, 3);
  padding: 1em;
  border-radius: 0.7em;
  margin: 1em;
  cursor: pointer;
  box-shadow: 0 0.5em 0.7em rgba(31, 168, 3, 0.5);
}
canvas {
  position: fixed;
  top: 0;
  right: 0;
  margin: 5em;
  width: 30em !important;
  height: 30em !important;
  transition: 300ms;
  /* transform: scaleX(0.7) scaleY(0.7); */
  border-radius: 1em;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
/* canvas:hover {
  transform: none;
  border-style: solid;
  border-width: 0.5em;
  border-color: #fff;
  box-shadow: 0, 0.5em 1em rgba(0, 0, 0, 0.3);
  border-radius: 0;
} */
a {
  color: #000 !important;
}
label {
  color: #000;
}
.arroundPrices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10em;
  padding-right: 10em;
  margin-bottom: 5em;
}
.cardPrice {
  width: 30em;
  height: 32em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  border-style: solid;
  border-width: 0.1em;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  text-align: center;
  overflow: hidden;
}
.cardPriceTop {
  padding: 1em;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.cardPrice h1 {
  font-family: "Myriad-Pro-Bold";
}
.buyBtn {
  width: 100%;
  height: 3em;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  padding: 2em;
  bottom: 0;
  max-width: 30em;
  bottom: 0em;
  cursor: pointer;
  bottom: -3em;
  transition: 700ms;
  background-repeat: no-repeat;
  background-size: 2em;
  background-position: -2.4em center;
  background-image: url("./assets/buy-btn.svg");
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}
.buyBtn:hover {
  background-position: 2em center;
}
.buyBtn:hover img {
  transform: scale(1.5);
  opacity: 1;
  position: relative;
}
@media only screen and (max-width: 1260px) {
  .pricesPageFormat {
    padding-top: 10em;
    padding-left: 0;
    padding-right: 0;
  }
  .arroundPrices {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 15em;
    justify-content: center;
    align-items: center;
  }
  .cardPrice {
    margin-bottom: 2em;
  }
}
@media only screen and (max-width: 600px) {
  .item {
    /* width: 20em;
    height: 20em; */
    padding: 0;
    margin: 0;
  }
  .App {
    padding-top: 3em;
  }
  .pages {
    margin-left: 2em !important;
  }
  .pages span {
    margin: 1em !important;
  }
  .previewView {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
  }
  .fraseInput {
    width: 100%;
  }
  .leftSideView {
    width: 100vw;
    padding: 1em;
    margin: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  .contentView {
    overflow: hidden;
    padding: 0;
    margin: 0%;
    flex-direction: column;
  }
  .inputText {
    max-width: 90vw;
  }
}
